<div class="credits-wrapper">
  <div [class.collapsed]="collapsed" [class.mobile]="(appService.isDesktop$ | async) === false" class="credits-header">
    <div class="credits-info-card">
      <div class="available-credits">
        <div class="first-row">
          <span class="bold">{{ translationService.translations.credits.AvailableCredits }}</span>
        </div>
        <div class="second-row">
          <mat-icon
            *ngIf="(appService.isDesktop$ | async) === true"
            [svgIcon]="settingsService.themeClass === 'dark-theme' ? 'enthus-credits' : 'enthus-credits'"
          ></mat-icon>
          <span class="bold">{{ credits.AvailableCredits | number: '1.2-2' : translationService.locale }}</span>
        </div>
        <div class="third-row mat-mdc-card-subtitle">
          <span>{{ translationService.translations.credits.CurrentCredits }} + {{ translationService.translations.credits.OutgoingCredits }}</span>
        </div>
        <div class="fourth-row">
          <button *ngIf="creditItems.length > 0" (click)="openLoadCreditsDialog()" color="primary" mat-raised-button>
            {{ translationService.translations.credits.LoadCredits }}
          </button>
        </div>
      </div>
      <div class="credits-info">
        <div class="credits-info-content">
          <div class="row">
            <span class="description">{{ translationService.translations.credits.CurrentCredits }}</span>
            <span class="value">{{ credits.CurrentCredits | number: '1.2-2' : translationService.locale }}</span>
          </div>
          <mat-divider></mat-divider>
          <mat-accordion [multi]="false">
            <mat-expansion-panel #expansionPanelOutgoingCredits [expanded]="(appService.isDesktop$ | async) === false" [togglePosition]="'before'">
              <mat-expansion-panel-header>
                <div class="row">
                  <span class="description">{{ translationService.translations.credits.OutgoingCredits }}</span>
                  <span class="value">{{ credits.OutgoingCredits | number: '1.2-2' : translationService.locale }}</span>
                </div>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <ng-container *ngIf="credits.OutgoingCreditDocuments.length > 0">
                <div class="row" *ngFor="let order of credits.OutgoingCreditDocuments">
                  <span class="description order-with-open-credits">
                    {{ translationService.translations.credits.CreditsOrderNo }}:&nbsp;
                    <portal-number-link [linkNumber]="order.OrderNo" type="Order"> </portal-number-link>
                  </span>
                  <span class="value">{{ order.Credits | number: '1.2-2' : translationService.locale }}</span>
                </div>
              </ng-container>
              <div *ngIf="credits.OutgoingCreditDocuments.length === 0" class="no-open-credits-placeholder-container">
                <div class="no-open-credits-placeholder">{{ translationService.translations.credits.NoOpenCredits }}</div>
              </div>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <mat-expansion-panel [togglePosition]="'before'" #expansionPanelIncomingCredits>
              <mat-expansion-panel-header
                [matTooltip]="
                  expansionPanelIncomingCredits.expanded
                    ? translationService.translations.app.Collapse.toString()
                    : translationService.translations.app.Expand.toString()
                "
              >
                <div class="row">
                  <span class="description">{{ translationService.translations.credits.IncomingCredits }}</span>
                  <span class="value">{{ credits.IncomingCredits | number: '1.2-2' : translationService.locale }}</span>
                </div>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <ng-container *ngIf="credits.IncomingCreditDocuments.length > 0">
                <div class="row" *ngFor="let order of credits.IncomingCreditDocuments">
                  <span class="description order-with-open-credits">
                    {{ translationService.translations.credits.CreditsOrderNo }}:&nbsp;
                    <portal-number-link [linkNumber]="order.OrderNo" type="Order"> </portal-number-link>
                  </span>
                  <span class="value">{{ order.Credits | number: '1.2-2' : translationService.locale }}</span>
                </div>
              </ng-container>
              <div *ngIf="credits.IncomingCreditDocuments.length === 0" class="no-open-credits-placeholder-container">
                <div class="no-open-credits-placeholder">{{ translationService.translations.credits.NoOpenCredits }}</div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="(appService.isDesktop$ | async) === false" class="collapse-button">
        <div class="collapse-triangle" (click)="collapsed = !collapsed">
          <mat-icon [svgIcon]="'enthus-expand_less'"></mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(appService.isDesktop$ | async) === true" class="credits-history">
    <ng-container *ngTemplateOutlet="desktopCreditsHistory"></ng-container>
  </div>
  <div
    *ngIf="(appService.isDesktop$ | async) === false"
    class="credits-history"
    [class.mobile]="(appService.isDesktop$ | async) === false"
    [class.collapsed]="collapsed"
  >
    <ng-container *ngTemplateOutlet="mobileCreditsHistory"></ng-container>
  </div>
</div>

<ng-template #desktopCreditsHistory>
  <ejs-grid
    [allowFiltering]="true"
    [allowResizing]="true"
    [allowSorting]="true"
    [allowPaging]="true"
    [dataSource]="credits.CreditDocuments"
    [filterSettings]="filterSettings"
    [pageSettings]="pageSettings"
    [toolbar]="toolbar"
    [showColumnMenu]="true"
    gridLines="Both"
    [height]="'100%'"
  >
    <e-columns>
      <e-column
        [width]="defaultMinWidth"
        [minWidth]="defaultMinWidth"
        field="OrderNo"
        headerText="{{ translationService.translations.credits.CreditsOrderNo }}"
        [type]="'string'"
      >
        <ng-template #template let-data>
          <portal-number-link (click)="$event.stopPropagation()" [linkNumber]="data.OrderNo" [type]="'Order'"></portal-number-link>
        </ng-template>
      </e-column>
      <e-column
        [width]="defaultMinWidth"
        [minWidth]="defaultMinWidth"
        field="InvoiceNo"
        headerText="{{ translationService.translations.credits.CreditInvoiceCreditnoteNo }}"
        [type]="'string'"
      >
        <ng-template #template let-data>
          <portal-number-link
            [type]="data.InvoiceIsCreditnote ? 'Invoice' : 'Credit note'"
            [linkNumber]="data.InvoiceNo"
            (click)="$event.stopPropagation()"
          ></portal-number-link>
        </ng-template>
      </e-column>
      <e-column
        [width]="defaultMinWidth"
        [minWidth]="defaultMinWidth"
        field="InvoiceIsCanceled"
        textAlign="right"
        headerText="{{ translationService.translations.credits.InvoiceIsCanceled }}"
        [type]="'boolean'"
      >
        <ng-template #template let-data>
          {{ data.InvoiceIsCanceled ? translationService.translations.common.Yes : translationService.translations.common.No }}
        </ng-template>
      </e-column>
      <e-column
        [width]="defaultMinWidth"
        [minWidth]="defaultMinWidth"
        field="Credits"
        textAlign="right"
        headerText="{{ translationService.translations.credits.CreditValue }}"
        [type]="'number'"
      >
        <ng-template #template let-data>
          <span [class.negative-credits]="data.Credits < 0" [class.positive-credits]="data.Credits > 0" class="bold">{{
            data.Credits | number: '1.2-2' : translationService.locale
          }}</span>
        </ng-template>
      </e-column>
      <e-column
        [width]="defaultMinWidth"
        [minWidth]="defaultMinWidth"
        field="CreditsAccumulated"
        textAlign="right"
        headerText="{{ translationService.translations.credits.CreditValueAccumulated }}"
        [type]="'number'"
      >
        <ng-template #template let-data>
          <span [class.negative-credits]="data.CreditsAccumulated < 0" [class.positive-credits]="data.CreditsAccumulated > 0" class="bold">
            {{ data.CreditsAccumulated | number: '1.2-2' : translationService.locale }}
          </span>
        </ng-template>
      </e-column>
      <e-column
        [width]="defaultMinWidth"
        [minWidth]="defaultMinWidth"
        [format]="translationService.currentDateFormatOptions"
        field="InvoiceDate"
        headerText="{{ translationService.translations.credits.CreditInvoiceCreditnoteDate }}"
        type="date"
        [type]="'date'"
      ></e-column>
    </e-columns>
  </ejs-grid>
</ng-template>
<ng-template #mobileCreditsHistory>
  <ng-container *ngIf="credits.CreditDocuments.length <= 0">
    <portal-marker-sketch
      [align]="'center'"
      [svgIcon]="'enthus-playlist_remove'"
      [infoMessage]="translationService.translations.credits.NoCreditsHistory.toString()"
    ></portal-marker-sketch>
  </ng-container>

  <div *ngIf="credits.CreditDocuments.length > 0" class="credits-history-wrapper">
    <mat-card *ngFor="let creditDocument of credits.CreditDocuments">
      <mat-card-content>
        <div class="row">
          <span class="description">{{ translationService.translations.credits.CreditsOrderNo }}</span>
          <portal-number-link [linkNumber]="creditDocument.OrderNo" [type]="'Order'"></portal-number-link>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <span class="description">{{ translationService.translations.credits.CreditInvoiceCreditnoteNo }}</span>
          <portal-number-link
            [type]="creditDocument.InvoiceIsCreditnote ? 'Invoice' : 'Credit note'"
            [linkNumber]="creditDocument.InvoiceNo"
          ></portal-number-link>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <span class="description">{{ translationService.translations.credits.InvoiceIsCanceled }}</span>
          <span>{{ creditDocument.InvoiceIsCanceled ? translationService.translations.common.Yes : translationService.translations.common.No }}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <span class="description">{{ translationService.translations.credits.CreditValue }}</span>
          <span [class.negative-credits]="creditDocument.Credits < 0" [class.positive-credits]="creditDocument.Credits > 0" class="bold">{{
            creditDocument.Credits | number: '1.2-2' : translationService.locale
          }}</span>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <span class="description">{{ translationService.translations.credits.CreditValueAccumulated }}</span>
          <span [class.negative-credits]="creditDocument.CreditsAccumulated < 0" [class.positive-credits]="creditDocument.CreditsAccumulated > 0" class="bold">
            {{ creditDocument.CreditsAccumulated | number: '1.2-2' : translationService.locale }}
          </span>
        </div>
        <mat-divider></mat-divider>
        <div class="row">
          <span class="description">{{ translationService.translations.credits.CreditInvoiceCreditnoteDate }}</span>
          <span>{{ creditDocument.InvoiceDate | date: translationService.currentDateFormat }}</span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
